<template>
    <div class="publications">
        <Publications />
    </div>
</template>


<script>
import Publications from "@/components/Publications.vue";

export default {
    components: {
        Publications
    }
};
</script>
